<div class="aboutHeader" id="firstHeader">
    <H1 class="headerTopLine">REMAN</H1>
    <h1 class="headerBottomLine">CONNECTION</h1>
    </div>


<div id='content'>
<!-- <div *ngFor="let folder of folders" class="year">
{{folder.name}}
<div class="files">
<div *ngFor="let file of folder.files" >
    <a href="javascript:void(0)" (click)="downloaFile(file.Id)">{{file.Name.slice(0, -4)}}</a>
</div>
</div> -->

<div  class="year">
    2024
    <div class="files">

       

        <div>
            
            <a href="../../assets/remanConnections/r2024/April 2024.pdf" target="_blank" class="mag"><img src="../../assets/images/r2024/April_2024.jpg" alt=""> <span>April</span></a>

            </div>

           
        
        </div>


<div  class="year">
    2023
    <div class="files">

       

        <div>
            
            <a href="../../assets/remanConnections/r2023/April 2023.pdf" target="_blank" class="mag"><img src="../../assets/images/r2023/April_2023.jpg" alt=""> <span>April</span></a>

            </div>

            <div>
            
                <a href="../../assets/remanConnections/r2023/August 2023.pdf" target="_blank" class="mag"><img src="../../assets/images/r2023/August_2023.jpg" alt=""> <span>August</span></a>
    
                </div>

                <div>
            
                    <a href="../../assets/remanConnections/r2023/October 2023.pdf" target="_blank" class="mag"><img src="../../assets/images/r2023/October_2023.jpg" alt=""> <span>October</span></a>
        
                    </div>

                    <div>
            
                        <a href="../../assets/remanConnections/r2023/December 2023.pdf" target="_blank" class="mag"><img src="../../assets/images/r2023/December_2023.jpg" alt=""> <span>December</span></a>
            
                        </div>
      
        
        </div>

<div  class="year">
    2022
    <div class="files">
      

        <div>
            
            <a href="../../assets/remanConnections/r2022/January 2022.pdf" target="_blank" class="mag"><img src="../../assets/images/r2022/January.jpg" alt=""> <span>January</span></a>

            </div>
            <div>

            <a href="../../assets/remanConnections/r2022/April 2022.pdf" target="_blank" class="mag"><img src="../../assets/images/r2022/April.jpg" alt=""> <span>April</span></a>
        </div>
        <div>

            <a href="../../assets/remanConnections/r2022/August 2022.pdf" target="_blank" class="mag"><img src="../../assets/images/r2022/August.jpg" alt=""> <span>August</span></a>
        </div>
    
        
        </div>

<div  class="year">
    2021
    <div class="files">
      

        <div>
            
            <a href="../../assets/remanConnections/r2021/March 2021.pdf" target="_blank" class="mag"><img src="../../assets/images/r2021/March.jpg" alt=""> <span>March</span></a>
        </div>
        
        <div>
            
            <a href="../../assets/remanConnections/r2021/June 2021.pdf" target="_blank" class="mag"><img src="../../assets/images/r2021/June.jpg" alt=""> <span>June</span></a>
        </div>

        <div>
            
            <a href="../../assets/remanConnections/r2021/October 2021.pdf" target="_blank" class="mag"><img src="../../assets/images/r2021/October.jpg" alt=""> <span>October</span></a>
        </div>

        
        </div>


<div  class="year">
    2020
    <div class="files">
        <div>
            <a href="../../assets/remanConnections/r2020/April 2020.pdf" target="_blank" class="mag"><img src="../../assets/images/r2020/April.jpg"><span>April</span></a>
        </div>
        
      
      
        <div>
            <a href="../../assets/remanConnections/r2020/august_2020.pdf" target="_blank" class="mag"><img src="../../assets/images/r2020/August.jpg"><span>August</span></a>
        </div>
      
       
        
        <div>
            <a href="../../assets/remanConnections/r2020/December_2020.pdf" target="_blank" class="mag"><img src="../../assets/images/r2020/December.jpg"><span>December</span></a>
        </div>
        
        </div>



<div  class="year">
    2019
    <div class="files">
        <div>
            <a href="../../assets/remanConnections/r2019/March 2019.pdf" target="_blank" class="mag"><img src="../../assets/images/r2019/March.jpg"><span>March</span></a>
        </div>
        
      
      
        <div>
            <a href="../../assets/remanConnections/r2019/August 2019.pdf" target="_blank" class="mag"><img src="../../assets/images/r2019/August.jpg"><span>August</span></a>
        </div>
       
        <div>
            <a href="../../assets/remanConnections/r2019/October 2019.pdf" target="_blank" class="mag"><img src="../../assets/images/r2019/October.jpg"><span>October</span></a>
        </div>
        
      
        <div>
            <a href="../../assets/remanConnections/r2019/December 2019.pdf" target="_blank" class="mag"><img src="../../assets/images/r2019/December.jpg"><span>December</span></a>
        </div>
        <div>
            <a href="../../assets/remanConnections/r2019/Big-R Guide 2019.pdf" target="_blank" class="mag"><img src="../../assets/images/r2019/Big-R.jpg"><span>Big-R Guide</span></a>
        </div>
        </div>



<div  class="year">
    2018
    <div class="files">
    <div>
        <a href="../../assets/remanConnections/r2018/Issue1 2018.pdf" target="_blank" class="mag"><img src="../../assets/images/r2018/Issue1.jpg"><span>Issue 1</span></a>
    </div>
    
    <div>
        <a href="../../assets/remanConnections/r2018/Issue2 2018.pdf" target="_blank" class="mag"><img src="../../assets/images/r2018/Issue2.jpg"><span>Issue 2</span></a>
    </div>
    </div>


<div  class="year">
    2017
    <div class="files">
    <div>
        <a href="../../assets/remanConnections/r2017/Issue 1 2017.pdf" target="_blank" class="mag"><img src="../../assets/images/r2017/Issue1.jpg"><span>Issue 1</span></a>
    </div>
    
    <div>
        <a href="../../assets/remanConnections/r2017/Issue2 2017.pdf" target="_blank" class="mag"><img src="../../assets/images/r2017/Issue2.jpg"><span>Issue 2</span></a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2017/Issue 3 2017.pdf" target="_blank" class="mag"><img src="../../assets/images/r2017/Issue3.jpg"><span>Issue 3</span></a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2017/Issue 4 2017.pdf" target="_blank" class="mag"><img src="../../assets/images/r2017/Issue4.jpg"><span>Issue 4</span></a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2017/Issue 5 2017.pdf" target="_blank" class="mag"><img src="../../assets/images/r2017/Issue5.jpg"><span>Issue 5</span></a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2017/Issue 6 2017.pdf" target="_blank" class="mag"><img src="../../assets/images/r2017/Issue6.jpg"><span>Issue 6</span></a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2017/Issue 7-8 2017.pdf" target="_blank" class="mag"><img src="../../assets/images/r2017/Issue7_8.jpg"><span>Issue 7-8</span></a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2017/Issue 9-10 2017.pdf" target="_blank" class="mag"><img src="../../assets/images/r2017/Issue9_10.jpg"><span>Issue 9-10</span></a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2017/Issue 11-12 2017.pdf" target="_blank" class="mag"><img src="../../assets/images/r2017/Issue11_12.jpg"><span>Issue 11-12</span></a>
    </div>
   
    </div>


<div  class="year">
    2016
    <div class="files">
    <div>
        <a href="../../assets/remanConnections/r2016/Jan-Feb_2016.pdf" target="_blank" class="mag"><img src="../../assets/images/r2016/January_February.jpg"><span>January/February</span></a>
    </div>
    
    <div>
        <a href="../../assets/remanConnections/r2016/March 2016.pdf" target="_blank" class="mag"><img src="../../assets/images/r2016/March.jpg"><span>March</span></a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2016/April 2016.pdf" target="_blank" class="mag"><img src="../../assets/images/r2016/April.jpg"><span>April</span></a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2016/May 2016.pdf" target="_blank" class="mag"><img src="../../assets/images/r2016/May.jpg"><span>May</span></a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2016/June 2016.pdf" target="_blank" class="mag"><img src="../../assets/images/r2016/June.jpg"><span>June</span></a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2016/July 2016.pdf" target="_blank" class="mag"><img src="../../assets/images/r2016/July.jpg"><span>July</span></a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2016/August 2016.pdf" target="_blank" class="mag"><img src="../../assets/images/r2016/August.jpg"><span>August</span></a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2016/September 2016.pdf" target="_blank" class="mag"><img src="../../assets/images/r2016/September.jpg"><span>September</span></a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2016/October 2016.pdf" target="_blank" class="mag"><img src="../../assets/images/r2016/October.jpg"><span>October</span></a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2016/November 2016.pdf" target="_blank" class="mag"><img src="../../assets/images/r2016/November.jpg"><span>November</span></a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2016/December 2016.pdf" target="_blank" class="mag"><img src="../../assets/images/r2016/December.jpg"><span>December</span></a>
    </div>
    </div>

<div  class="year">
    2015
    <div class="files">
    <div>
        <a href="../../assets/remanConnections/r2015/January 2015.pdf" target="_blank">January</a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2015/February 2015.pdf" target="_blank">February</a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2015/March 2015.pdf" target="_blank">March</a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2015/April 2015.pdf" target="_blank">April</a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2015/May 2015.pdf" target="_blank">May</a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2015/June 2015.pdf" target="_blank">June</a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2015/July 2015.pdf" target="_blank">July</a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2015/August 2015.pdf" target="_blank">August</a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2015/September 2015.pdf" target="_blank">September</a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2015/October 2015.pdf" target="_blank">October</a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2015/November 2015.pdf" target="_blank">November</a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2015/December 2015.pdf" target="_blank">December</a>
    </div>
    </div>

    <div  class="year">
        2014
        <div class="files">
        <div>
            <a href="../../assets/remanConnections/r2014/January 2014.pdf" target="_blank">January</a>
        </div>
        <div>
            <a href="../../assets/remanConnections/r2014/February 2014.pdf" target="_blank">February</a>
        </div>
        <div>
            <a href="../../assets/remanConnections/r2014/March 2014.pdf" target="_blank">March</a>
        </div>
        <div>
            <a href="../../assets/remanConnections/r2014/April 2014.pdf" target="_blank">April</a>
        </div>
        <div>
            <a href="../../assets/remanConnections/r2014/May 2014.pdf" target="_blank">May</a>
        </div>
        <div>
            <a href="../../assets/remanConnections/r2014/June 2014.pdf" target="_blank">June</a>
        </div>
        <div>
            <a href="../../assets/remanConnections/r2014/July 2014.pdf" target="_blank">July</a>
        </div>
        <div>
            <a href="../../assets/remanConnections/r2014/August 2014.pdf" target="_blank">August</a>
        </div>
        <div>
            <a href="../../assets/remanConnections/r2014/September 2014.pdf" target="_blank">September</a>
        </div>
        <div>
            <a href="../../assets/remanConnections/r2014/October 2014.pdf" target="_blank">October</a>
        </div>
        <div>
            <a href="../../assets/remanConnections/r2014/November 2014.pdf" target="_blank">November</a>
        </div>
        <div>
            <a href="../../assets/remanConnections/r2014/December 2014.pdf" target="_blank">December</a>
        </div>
        </div>

        <div  class="year">
            2013
            <div class="files">
            <div>
                <a href="../../assets/remanConnections/r2013/January 2013 .pdf" target="_blank">January</a>
            </div>
            <div>
                <a href="../../assets/remanConnections/r2013/February 2013 .pdf" target="_blank">February</a>
            </div>
            <div>
                <a href="../../assets/remanConnections/r2013/March 2013 .pdf" target="_blank">March</a>
            </div>
            <div>
                <a href="../../assets/remanConnections/r2013/April 2013 .pdf" target="_blank">April</a>
            </div>
            <div>
                <a href="../../assets/remanConnections/r2013/May 2013 .pdf" target="_blank">May</a>
            </div>
            <div>
                <a href="../../assets/remanConnections/r2013/June 2013 .pdf" target="_blank">June</a>
            </div>
            <div>
                <a href="../../assets/remanConnections/r2013/July 2013 .pdf" target="_blank">July</a>
            </div>
            <div>
                <a href="../../assets/remanConnections/r2013/August 2013 .pdf" target="_blank">August</a>
            </div>
            <div>
                <a href="../../assets/remanConnections/r2013/September 2013 .pdf" target="_blank">September</a>
            </div>
            <div>
                <a href="../../assets/remanConnections/r2013/October 2013 .pdf" target="_blank">October</a>
            </div>
            <div>
                <a href="../../assets/remanConnections/r2013/November 2013 .pdf" target="_blank">November</a>
            </div>
            <div>
                <a href="../../assets/remanConnections/r2013/December 2013 .pdf" target="_blank">December</a>
            </div>
            </div>

            <div  class="year">
                2012
                <div class="files">
                <div>
                    <a href="../../assets/remanConnections/r2012/January 2012.pdf" target="_blank">January</a>
                </div>
                <div>
                    <a href="../../assets/remanConnections/r2012/February 2012.pdf" target="_blank">February</a>
                </div>
                <div>
                    <a href="../../assets/remanConnections/r2012/March 2012.pdf" target="_blank">March</a>
                </div>
                <div>
                    <a href="../../assets/remanConnections/r2012/April 2012.pdf" target="_blank">April</a>
                </div>
                <div>
                    <a href="../../assets/remanConnections/r2012/May 2012.pdf" target="_blank">May</a>
                </div>
                <div>
                    <a href="../../assets/remanConnections/r2012/June 2012.pdf" target="_blank">June</a>
                </div>
                <div>
                    <a href="../../assets/remanConnections/r2012/July 2012.pdf" target="_blank">July</a>
                </div>
                <div>
                    <a href="../../assets/remanConnections/r2012/August 2012.pdf" target="_blank">August</a>
                </div>
                <div>
                    <a href="../../assets/remanConnections/r2012/September 2012.pdf" target="_blank">September</a>
                </div>
                <div>
                    <a href="../../assets/remanConnections/r2012/October 2012.pdf" target="_blank">October</a>
                </div>
                <div>
                    <a href="../../assets/remanConnections/r2012/November 2012.pdf" target="_blank">November</a>
                </div>
                <div>
                    <a href="../../assets/remanConnections/r2012/December 2012.pdf" target="_blank">December</a>
                </div>
                </div>

                <div  class="year">
                    2011
                    <div class="files">
                    <div>
                        <a href="../../assets/remanConnections/r2011/January 2011.pdf" target="_blank">January</a>
                    </div>
                    <div>
                        <a href="../../assets/remanConnections/r2011/February 2011.pdf" target="_blank">February</a>
                    </div>
                    <div>
                        <a href="../../assets/remanConnections/r2011/March 2011.pdf" target="_blank">March</a>
                    </div>
                    <div>
                        <a href="../../assets/remanConnections/r2011/April 2011.pdf" target="_blank">April</a>
                    </div>
                    <div>
                        <a href="../../assets/remanConnections/r2011/May 2011.pdf" target="_blank">May</a>
                    </div>
                    <div>
                        <a href="../../assets/remanConnections/r2011/June 2011.pdf" target="_blank">June</a>
                    </div>
                    <div>
                        <a href="../../assets/remanConnections/r2011/July 2011.pdf" target="_blank">July</a>
                    </div>
                    <div>
                        <a href="../../assets/remanConnections/r2011/August 2011.pdf" target="_blank">August</a>
                    </div>
                    <div>
                        <a href="../../assets/remanConnections/r2011/September 2011.pdf" target="_blank">September</a>
                    </div>
                    <div>
                        <a href="../../assets/remanConnections/r2011/October 2011.pdf" target="_blank">October</a>
                    </div>
                    <div>
                        <a href="../../assets/remanConnections/r2011/November 2011.pdf" target="_blank">November</a>
                    </div>
                    <div>
                        <a href="../../assets/remanConnections/r2011/December 2011.pdf" target="_blank">December</a>
                    </div>
                    </div>

                    <div  class="year">
                        2010
                        <div class="files">
                        <div>
                            <a href="../../assets/remanConnections/r2010/January 2010.pdf" target="_blank">January</a>
                        </div>
                        <div>
                            <a href="../../assets/remanConnections/r2010/February 2010.pdf" target="_blank">February</a>
                        </div>
                        <div>
                            <a href="../../assets/remanConnections/r2010/March 2010.pdf" target="_blank">March</a>
                        </div>
                        <div>
                            <a href="../../assets/remanConnections/r2010/April 2010.pdf" target="_blank">April</a>
                        </div>
                        <div>
                            <a href="../../assets/remanConnections/r2010/May 2010.pdf" target="_blank">May</a>
                        </div>
                        <div>
                            <a href="../../assets/remanConnections/r2010/June 2010.pdf" target="_blank">June</a>
                        </div>
                        <div>
                            <a href="../../assets/remanConnections/r2010/July 2010.pdf" target="_blank">July</a>
                        </div>
                        <div>
                            <a href="../../assets/remanConnections/r2010/August 2010.pdf" target="_blank">August</a>
                        </div>
                        <div>
                            <a href="../../assets/remanConnections/r2010/September 2010.pdf" target="_blank">September</a>
                        </div>
                        <div>
                            <a href="../../assets/remanConnections/r2010/October 2010.pdf" target="_blank">October</a>
                        </div>
                        <div>
                            <a href="../../assets/remanConnections/r2010/November 2010.pdf" target="_blank">November</a>
                        </div>
                        <div>
                            <a href="../../assets/remanConnections/r2010/December 2010.pdf" target="_blank">December</a>
                        </div>
                        </div>

                        <div  class="year">
                            2009
                            <div class="files">
                            <div>
                                <a href="../../assets/remanConnections/r2009/January 2009.pdf" target="_blank">January</a>
                            </div>
                            <div>
                                <a href="../../assets/remanConnections/r2009/February 2009.pdf" target="_blank">February</a>
                            </div>
                            <div>
                                <a href="../../assets/remanConnections/r2009/March 2009.pdf" target="_blank">March</a>
                            </div>
                            <div>
                                <a href="../../assets/remanConnections/r2009/April 2009.pdf" target="_blank">April</a>
                            </div>
                            <div>
                                <a href="../../assets/remanConnections/r2009/May 2009.pdf" target="_blank">May</a>
                            </div>
                            <div>
                                <a href="../../assets/remanConnections/r2009/June 2009.pdf" target="_blank">June</a>
                            </div>
                            <div>
                                <a href="../../assets/remanConnections/r2009/July 2009.pdf" target="_blank">July</a>
                            </div>
                            <div>
                                <a href="../../assets/remanConnections/r2009/August 2009.pdf" target="_blank">August</a>
                            </div>
                            <div>
                                <a href="../../assets/remanConnections/r2009/September 2009.pdf" target="_blank">September</a>
                            </div>
                            <div>
                                <a href="../../assets/remanConnections/r2009/October 2009.pdf" target="_blank">October</a>
                            </div>
                            <div>
                                <a href="../../assets/remanConnections/r2009/nov" target="_blank">November</a>
                            </div>
                            <div>
                                <a href="../../assets/remanConnections/r2009/December 2009.pdf" target="_blank">December</a>
                            </div>
                            </div>
                        

<div  class="year">
    2008
    <div class="files">
    <div>
        <a href="../../assets/remanConnections/r2008/January 2008.pdf" target="_blank">January</a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2008/February 2008.pdf" target="_blank">February</a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2008/March 2008.pdf" target="_blank">March</a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2008/April 2008.pdf" target="_blank">April</a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2008/May 2008.pdf" target="_blank">May</a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2008/June 2008.pdf" target="_blank">June</a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2008/July 2008.pdf" target="_blank">July</a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2008/August 2008.pdf" target="_blank">August</a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2008/September 2008.pdf" target="_blank">September</a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2008/October 2008.pdf" target="_blank">October</a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2008/November 2008.pdf" target="_blank">November</a>
    </div>
    <div>
        <a href="../../assets/remanConnections/r2008/December 2008.pdf" target="_blank">December</a>
    </div>
    </div>






</div>


</div>

<app-footer></app-footer>
